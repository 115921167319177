import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Typography, TextField, Button, Link, FormControlLabel, Checkbox, Grid, CircularProgress } from '@mui/material';
import theme from '../../theme'
import { useNavigate } from 'react-router-dom';
import { useSignupMutation } from '../../services/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginValidation } from '../../yup';
import { Box, getValue } from '@mui/system';
import { toast } from "react-toastify";
import styles from './index.module.css';
import ReactPasswordChecklist from 'react-password-checklist';
import { useAppDispatch } from '../../store/store';
import { setUser } from '../../store/reducers/usersReducer';
import { setUserLogin } from '../../store/reducers/authReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '180px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '16px',
    // backgroundColor: 'gray',
  },
  form: {
    width: '100%',
    marginTop: '4px',
  },
  submit: {
    margin: '15px 0px 2px',
  },
}));

function Register() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [result, { error, isLoading }] = useSignupMutation();
  const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm<Login>({
    resolver: yupResolver(loginValidation),
  });

  const [formData, setFormData] = useState<Login>({
    email: "",
    password: ""
  });

  useEffect(() => {
    setPasswordValue(formData.password);
    setValue("password", formData.password);
    clearErrors('password');
  }, [formData.password]);

  const [passwordValue, setPasswordValue] = useState<string>("");
  const [isPasswordFocused, setIsPasswordFocused] = useState<boolean>(false);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegister =  async(formData: Login) => {
    const { data, error } = await (result(formData)) as Response<LoginResp>;
    if(error){
      // @ts-ignore
      toast.error(error?.data?.message, {position: 'top-right'});
      return;
    }
    if (data?.data) {
      toast.success(data?.message, {position: 'top-right'});
      localStorage.setItem("token", data?.data?.token);
      localStorage.setItem("user", JSON.stringify(data?.data?.user));
      dispatch(setUser(data?.data?.user));
      dispatch(setUserLogin({token: data?.data?.token}));
      navigate("/");
    } else {
      console.log("Error")
    }
  }

  const onSubmit = (data: Login) => {
    handleRegister(data)
  };

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) navigate(-1);
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.root}>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email"
            autoComplete="email"
            autoFocus
            {...register("email")}
          />
          <p style={{ color: "red", margin: "0 0 10px 0", fontSize: "13px" }}>
            {errors.email?.message}
          </p>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Password"
            type="password"
            // id="password"
            autoComplete="current-password"
            {...register("password")}
            onChange={(e) => {
              handleChange(e);
              setPasswordValue(e.target.value);
            }}
            onFocus={() => setIsPasswordFocused(true)}
          />
          <p style={{ color: "red", margin: "0 0 15px 0", fontSize: "13px" }}>
            {errors.password?.message}
          </p>
          <div className="passwordCheck">
            {isPasswordFocused && formData.password && (
              <ReactPasswordChecklist
                rules={[
                  "capital",
                  "lowercase",
                  "specialChar",
                  "number",
                  "minLength",
                ]}
                minLength={8}
                value={passwordValue}
                onChange={(isValid) => {}}
                iconSize={10}
                validTextColor="green"
                validColor="green"
                invalidTextColor="red"
                invalidColor="red"
                style={{ fontSize: "12px", marginBottom: "10px" }}
              />
            )}
          </div>
          <Grid container spacing={2}>
            <Grid item xs>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>
          <Grid container mt={5}>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item xs display={"flex"} justifyContent={"flex-end"}>
              <Link href="login" variant="body2">
                Already have an account?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      {isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9998,
          }}
        >
          <Box
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              zIndex: 9999,
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      )}
    </Container>
  );
}

export default Register;
