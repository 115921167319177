import { Call, Device } from "@twilio/voice-sdk";
import { CallMode } from "../providers/DeviceProvider";

export default class DeviceWorker {
  token: string;
  device: Device;
  identity: string;
  callRef?: Call;
  scriptId: number;
  callMode: CallMode;
  automatedCall: any;
  endCall: any;
  callSid?: string;
  showBarge: boolean;
  private _prospect?: Table;
  id: string;
  private _onMediaError: () => void;

  constructor(
    token: string,
    callMode: CallMode,
    params: {
      prospect: Table;
      identity: string;
      id: string;
      scriptId: number;
      automatedCall: any;
      endCall: any;
      onMediaError: () => void;
    }
  ) {
    this.token = token;
    this.identity = params.identity;
    this.id = params.id;
    this._onMediaError = params.onMediaError;
    this.scriptId = params.scriptId;
    this.callMode = callMode;
    this.automatedCall = params.automatedCall;
    this.endCall = params.endCall;
    this.showBarge = false;

    this.device = new Device(token, {
      codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
      enableImprovedSignalingErrorPrecision: true,
      tokenRefreshMs: 600000,
    });

    this.device.audio?.outgoing(false);
    // this.device.on("tokenWillExpire", () => {
    //   params.updateToken().then((data) => this.device.updateToken(data.token));
    // });
    this._call(params.prospect);
  }

  private async _call(pros: Table) {
    try {
      if (this.callMode === CallMode.DIRECT) {
        this.callRef?.disconnect();

        const params = {
          To: pros.phoneNo,
          callerId: this.identity,
          prospectId: `${pros.id}`,
          deviceId: `${this.id}`,
        };

        this.callRef = await this.device.connect({ params });
        this.callRef.on("error", async (error) => {
          if ([31401, 31402].includes(error.code)) this._onMediaError();
        });
        this._prospect = pros;
        return this.callRef;
      } else {
        await this.device.register();
        const {
          data: { data: call },
        } = await this.automatedCall({
          scriptId: this.scriptId,
          prospectId: pros.id,
          callerId: this.identity,
          mode: this.callMode,
          deviceId: `${this.id}`,
        });
        this._prospect = pros;
        this.callSid = call.sid;
        this.device.on("incoming", (call) => call.accept());
        if (this.callMode === CallMode.AI) this.showBarge = true;
      }
    } catch (error) {
      // scriptId: number;
      // prospectIds: string[];
      // callerId: string;
      // mode: CallMode;
      this.device.emit("error", new Error());
    }
  }

  get prospect() {
    return this._prospect;
  }

  disconnect() {
    if (this.callMode !== CallMode.DIRECT)
      this.endCall({ callSid: this.callSid });
    this.callRef?.disconnect();
    this.device.disconnectAll();
    this.device.destroy();
  }
}
