import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Fade,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./index.module.css";
import {
  useAddScriptMutation,
  useEditScriptMutation,
} from "../../../services/api";
import { useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

type Props = {
  closeHandler: (refresh?: boolean) => void;
  templates: Template[];
  script?: Script;
  edit?: boolean;
};

type Error = {
  [key: string]: string;
};

const ScriptModal = ({ closeHandler, templates, script, edit }: Props) => {
  const [addScript, { isLoading: isAddLoading }] = useAddScriptMutation();
  const [editScript, { isLoading: isEditLoading }] = useEditScriptMutation();
  const [name, setName] = useState<string>(script?.name || "");
  const [content, setContent] = useState<string>(script?.content || "");
  const [errors, setErrors] = useState<Error>({});
  const [template, setTemplate] = useState<Template | undefined>(
    script?.template || templates[0]
  );
  const contentInputRef = useRef<HTMLTextAreaElement>();

  const handleClick = async () => {
    if (!template) return;
    //prepare payload to send
    const payload = {
      name,
      content,
      templateId: template.id,
    };
    const { error } = (
      edit && script
        ? await editScript({ id: script.id, ...payload })
        : await addScript(payload)
    ) as {
      error: any;
    };

    //throw error if exists (validation errors are inside the error.data.data.errors array)
    if (error) {
      toast(
        error?.data?.message === "Validation error!"
          ? error.data.data.errors[0].msg
          : `Unknown error occured while ${
              edit ? "editing" : "creating"
            } script`,
        { type: "error" }
      );
      //map errors to key value pairs and store them in state
      return setErrors(
        error?.data?.data?.errors.reduce((acc: any, error: any) => {
          acc[error.path] = error.msg;
          return acc;
        }, {}) || []
      );
    }
    toast(`Script ${edit ? "edited" : "added"} successfully`);
    //call closeHandler with refresh set to true
    closeHandler(true);
  };

  const insertVariableAtCursor = (word: string) => {
    const inputField = contentInputRef.current;
    if (!inputField) return;
    const startPos = inputField.selectionStart;
    const endPos = inputField.selectionEnd;
    const textBeforeCursor = inputField.value.substring(0, startPos).trim();
    const textAfterCursor = inputField.value
      .substring(endPos, inputField.value.length)
      .trim();
    const newText = textBeforeCursor + word + textAfterCursor;
    setContent(newText);
  };

  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={true}
      onClose={() => closeHandler()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div className={styles.modal}>
          {false && (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                zIndex: 9998,
              }}
            >
              <Box
                sx={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  zIndex: 9999,
                }}
              >
                <CircularProgress />
              </Box>
            </Box>
          )}
          <div className={styles.modalBody}>
            <IconButton
              aria-label="close"
              onClick={() => closeHandler()}
              style={{
                position: "absolute",
                top: "20px",
                right: "5px",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{ margin: 0 }}
                id="transition-modal-title"
                style={{ position: "relative" }}
              >
                {edit ? "Edit" : "Add"} Script
              </Typography>
            </Box>
          </div>
          <div className={styles.modalContainer}>
            <TextField
              size="medium"
              value={template?.id}
              label={"Select Template"}
              fullWidth={true}
              select
              onChange={(e) =>
                setTemplate(
                  templates?.find((temp) => temp.id === e.target.value) ||
                    template
                )
              }
            >
              {templates.map((temp) => (
                <MenuItem value={temp.id}>{temp.name}</MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth={true}
              label="Name"
              placeholder="Enter a name for the script"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={!!errors["name"]}
              helperText={errors["name"]}
            />
            <TextField
              multiline={true}
              fullWidth={true}
              label="Content"
              placeholder="Enter content for the script"
              variant="outlined"
              value={content}
              inputRef={contentInputRef}
              onChange={(e) => setContent(e.target.value)}
              rows={10}
              error={!!errors["content"]}
              helperText={errors["content"]}
            />
            <div className={styles.pillContainer}>
              {template?.fields.map((pill) => (
                <Chip
                  label={pill}
                  variant="filled"
                  onClick={() => insertVariableAtCursor(` {{${pill}}} `)}
                />
              ))}
            </div>
            <div>
              <Button
                onClick={() => closeHandler()}
                variant="contained"
                color="error"
              >
                Cancel
              </Button>
              <Tooltip
                title={
                  !template ?? "Please add a list before creating any scripts"
                }
              >
                <span>
                  <LoadingButton
                    loading={edit ? isEditLoading : isAddLoading}
                    variant="contained"
                    onClick={handleClick}
                    color="secondary"
                    disabled={!template}
                  >
                    {edit ? "Edit" : "Add"} Script
                  </LoadingButton>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ScriptModal;
