import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../configs/env";
import { CallMode } from "../providers/DeviceProvider";
import { Call } from "@twilio/voice-sdk";
export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("ngrok-skip-browser-warning", "true");
      return headers;
    },
  }),
  tagTypes: ["Token", "Frequency"],
  endpoints: (builder) => ({
    login: builder.mutation<LoginResp, Login>({
      query: (body) => ({
        url: `users/login`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Token"],
    }),
    signup: builder.mutation<LoginResp, Login>({
      query: (body) => ({
        url: `users/signup`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Token"],
    }),
    importProspects: builder.mutation<any, any>({
      query: (body) => ({
        url: `prospects/import`,
        method: "POST",
        body,
      }),
    }),
    getAllProspects: builder.query<Response<Prospects[]>, void>({
      query: () => `prospects/?page=1&limit=10&query=`,
    }),
    deleteProspect: builder.mutation<any, number>({
      query: (id) => ({
        url: `prospects/${id}`,
        method: "DELETE",
      }),
    }),
    addProspectus: builder.mutation<Response<ProspectsAdd>, ProspectsAdd>({
      query: (body) => ({
        url: `prospects`,
        method: "POST",
        body,
      }),
    }),
    editProspectus: builder.mutation<Response<ProspectsEdit>, ProspectsEdit>({
      query: (body) => ({
        url: `prospects/${body.id}`,
        method: "PUT",
        body,
      }),
    }),
    uploadAudio: builder.mutation<any, any>({
      query: (body) => ({
        url: `voices`,
        method: "POST",
        body,
      }),
    }),
    getTemplates: builder.query<TemplateList, void>({
      query: () => `templates`,
    }),
    deleteTemplate: builder.mutation<any, number>({
      query: (id) => ({
        url: `templates/${id}`,
        method: "DELETE",
      }),
    }),
    getTemplateById: builder.query<
      TableResponse,
      { id: any; page: number; fields: string[] }
    >({
      query: ({ id = 0, page = 1 }) =>
        `prospects/template/${id}/?page=${page}&query=`,
      keepUnusedDataFor: 5,
      transformResponse: (respo: { data: [Table[], number] }, meta, args) => {
        const regex = new RegExp("phone", "i");
        const count = respo.data[1];
        const phoneKeys = args.fields.filter((field: string) =>
          regex.test(field)
        );
        const pros = respo.data[0].map((ele, index) => {
          if (phoneKeys.length) {
            for (let key of phoneKeys)
              if (ele.data[key])
                return { ...ele, phoneNo: ele.data[key], index };
          }

          return { ...ele, index };
        });

        return { data: pros, count };
      },
    }),
    callProspects: builder.mutation<
      any,
      {
        scriptId: number;
        prospectId: string;
        callerId: string;
        mode: CallMode;
        deviceId: string;
      }
    >({
      query: (body) => ({
        url: `voices/call/create`,
        method: "POST",
        body,
      }),
    }),
    bargeIn: builder.mutation<
      Call,
      {
        callSid: string;
        queue: string;
      }
    >({
      query: (body) => ({
        url: `voices/call/barge-in`,
        method: "POST",
        body,
      }),
    }),
    endCall: builder.mutation<
      any,
      {
        callSid: string;
      }
    >({
      query: (body) => ({
        url: `voices/call`,
        method: "DELETE",
        body,
      }),
    }),
    getAudio: builder.query<any, void>({
      query: () => ({
        url: `voices/`,
        responseHandler: (response: any) => {
          if (!response.ok) {
            return { error: response.statusText, status: response.status };
          }
          return response.blob();
        },
      }),
    }),
    getDeviceToken: builder.query<any, void>({
      query: () => ({
        url: `voices/token`,
      }),
      transformResponse: (resp: any) => resp.data,
      providesTags: ["Token"],
    }),
    getUserFrequency: builder.query<number, void>({
      query: () => "/users/frequency",
      providesTags: ["Frequency"],
      transformResponse: (resp: any) => resp.data?.frequency,
    }),
    updateFrequency: builder.mutation<void, number>({
      query: (freq) => ({
        url: `users/frequency/${freq}`,
        method: "PUT",
      }),
      invalidatesTags: ["Frequency"],
    }),
    getAllScripts: builder.query<ScriptList, void>({
      query: () => `scripts`,
    }),
    addScript: builder.mutation<Response<Script>, ScriptsAdd>({
      query: (body) => ({
        url: `scripts`,
        method: "POST",
        body,
      }),
    }),
    editScript: builder.mutation<Response<Script>, ScriptsEdit>({
      query: (body) => ({
        url: `scripts/${body.id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteScript: builder.mutation<any, number>({
      query: (id) => ({
        url: `scripts/${id}`,
        method: "DELETE",
      }),
    }),
    getScriptsByTemplateId: builder.query<ScriptList, string>({
      query: (templateId) => `scripts/template/${templateId}`,
    }),
  }),
});
export const {
  useLoginMutation,
  useSignupMutation,
  useImportProspectsMutation,
  useGetAllProspectsQuery,
  useDeleteProspectMutation,
  useAddProspectusMutation,
  useEditProspectusMutation,
  useUploadAudioMutation,
  useGetTemplatesQuery,
  useGetTemplateByIdQuery,
  useGetAudioQuery,
  useGetDeviceTokenQuery,
  useGetUserFrequencyQuery,
  useUpdateFrequencyMutation,
  useCallProspectsMutation,
  useEndCallMutation,
  useBargeInMutation,
  useGetAllScriptsQuery,
  useAddScriptMutation,
  useEditScriptMutation,
  useDeleteScriptMutation,
  useGetScriptsByTemplateIdQuery,
  useDeleteTemplateMutation,
} = api;
