export class MuLawDecoder {
  private BIAS = 0x84;
  private muLawToPcmMap: Int16Array;

  constructor() {
    this.muLawToPcmMap = new Int16Array(256);
    this.initializeMap();
  }

  private initializeMap(): void {
    for (let i = 0; i < 256; i++) {
      this.muLawToPcmMap[i] = this.decode(i);
    }
  }

  private decode(mulaw: number): number {
    // Flip all the bits
    mulaw = ~mulaw & 0xff;

    // Pull out the value of the sign bit
    const sign = mulaw & 0x80;
    // Pull out and shift over the value of the exponent
    const exponent = (mulaw & 0x70) >> 4;
    // Pull out the four bits of data
    let data = mulaw & 0x0f;

    // Add on the implicit fifth bit (we know the four data bits followed a one bit)
    data |= 0x10;
    // Add a 1 to the end of the data by shifting over and adding one.
    data = (data << 1) + 1;
    // Shift the five bits to where they need to be: left (exponent + 2) places
    data <<= exponent + 2;
    // Subtract the BIAS from the final value
    data -= this.BIAS;

    // If the sign bit is 0, the number is positive. Otherwise, negative.
    return sign === 0 ? data : -data;
  }

  public muLawDecode(mulaw: number): number {
    return this.muLawToPcmMap[mulaw];
  }

  public muLawDecodeBuffer(inputBuffer: ArrayBuffer): ArrayBuffer {
    const inputArray = new Uint8Array(inputBuffer);
    const size = inputArray.length;
    const outputBuffer = new ArrayBuffer(size * 2);
    const outputArray = new Int16Array(outputBuffer);

    for (let i = 0; i < size; i++) {
      outputArray[i] = this.muLawToPcmMap[inputArray[i]];
    }

    return outputBuffer;
  }
}
