import React, { createContext, useEffect, PropsWithChildren, useState, useContext } from "react";
import io, { Socket } from "socket.io-client";
import config from "../configs/env";
import { useAppSelector } from "../store/store";
import { useGetDeviceTokenQuery } from "../services/api";

interface SocketContextValue {
  socket: Socket | null;
}

export const SocketContext = createContext<SocketContextValue>({
  socket: null,
});

const SOCKET_SERVER_URL = new URL(config.baseUrl).origin;

const SocketProvider = (props: PropsWithChildren) => {

  const [socket, setSocket] = useState<Socket | null>(null)
  const token = useAppSelector((state) => state.auth.token);
  const { data } = useGetDeviceTokenQuery(undefined, { skip: !token });

  useEffect(() => {
    if (!data) return;
    const token = localStorage.getItem("token");
    const socket = io(SOCKET_SERVER_URL, {
      path: '/api/socket/',
      transports: ["polling"],
      auth: { token },
      query: { id: data?.identity },
    });
    socket.on("connect", () => {
      console.log("Connected to server");
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    setSocket(socket);

    return () => {
      socket.close();
    };
  }, [data])

  return (
    <SocketContext.Provider value={{ socket }}>
      {props.children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;

export const useSocket = () => useContext(SocketContext)