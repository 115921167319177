import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Box, Button, FormControl, Grid, TextField } from "@mui/material";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./index.module.css";
import { useEditProspectusMutation } from "../../services/api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const UpdateDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [editData, { error: editError, isLoading: editLoading }] =
    useEditProspectusMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IFormData>();

  const handleEditProspectus = async (formData: any) => {
    formData.id = location?.state?.row?.id;
    const IformData = {
      id: formData.id,
      name: formData.Name,
      phoneNo: formData.PhoneNo,
      email: formData.Email,
      company: formData.Company,
      title: formData.Title,
      linkedin: formData.Linkedin,
      note: formData.Note,
      status: formData.Status,
    };
    const data = (await editData(IformData)) as Response<ProspectsEdit>;
    if (data?.data?.success) {
      toast.success("Prospect Updated Successfully", { position: "top-right" });
    }
    if (data?.data) {
    } else {
      toast.error("Unable to update prospect", { position: "top-right" });
    }
  };
  useEffect(() => {
    setValue("Name", location?.state?.row?.name);
    setValue("PhoneNo", location?.state?.row?.phoneNo);
    setValue("Note", location?.state?.row?.note);
    setValue("Company", location?.state?.row?.company);
    setValue("Title", location?.state?.row?.title);
    setValue("Email", location?.state?.row?.email);
    setValue("LinkedIn", location?.state?.row?.linkedin);
  }, []);

  const handleBack = () => {
    navigate("/home");
  };

  if (location?.state === null) {
    return <Navigate to="/home" />;
  }

  const handleDisconnectCall = async (id: string) => {
    navigate("/home");
  };

  return (
    <div className={styles.pageConatiner}>
      <div className={styles.upperConatiner}>
        <div>
          <div className={styles.backBtn} onClick={handleBack}>
            <span>
              <ArrowBackIcon />
            </span>
            <p>Back</p>
          </div>
          <div className={styles.nameContainer}>
            <div>
              <span className={styles.callIcon}>
                {" "}
                <CallRoundedIcon />
              </span>
            </div>
            <div>
              <p className={styles.userStatus}>
                {location?.state?.row?.status}
              </p>
              <h3 className={styles.userName}>{location?.state?.row?.name}</h3>
            </div>
          </div>
        </div>
        <div>
          <Button
            type="submit"
            variant="contained"
            color="error"
            sx={{ mr: 1, fontWeight: "bold" }}
            style={{
              width: "130px",
              height: "45px",
            }}
            onClick={() => handleDisconnectCall(location.state.id)}
          >
            End Call
          </Button>
        </div>
      </div>
      <h4 className={styles.heading}>Prospect Details</h4>
      <form
        className={styles.formBox}
        onSubmit={handleSubmit(handleEditProspectus)}
      >
        <Grid container spacing={8}>
          {location?.state.row.template.fields.map((item: keyof IFormData) => {
            if (item === "Note") {
              return null;
            }
            return (
              <Grid item xs={6}>
                <TextField
                  id={item}
                  label={item}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={true}
                  sx={{ mb: 2 }}
                  inputProps={{ style: { height: "40px" } }}
                  {...register(item)}
                />
              </Grid>
            );
          })}
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mb: 2, mt: 6 }}>
            <TextField
              id="note"
              label="Pre-call Research"
              variant="outlined"
              size="small"
              multiline
              rows={8}
              {...register("Note")}
            />
          </FormControl>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mr: 1, fontWeight: "bold" }}
            style={{
              width: "130px",
              height: "45px",
              margin: "15px 0",
            }}
          >
            Update
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default UpdateDetails;
