import { useEffect, useState } from "react";

type Props = {
  onRefresh: () => void;
};

const useExitPrompt = (props: Props) => {
  const confirmationMessage =
    "Are you sure you want to leave this page?, Ongoing calls will be dropped";
  const [showExitPrompt, setShowExitPrompt] = useState(false);

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    // Display a confirmation message

    if (showExitPrompt) {
      event.preventDefault();
      props.onRefresh();
    } else {
      // Cancel the page refresh
      event.returnValue = "";
      return "";
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return { setShowExitPrompt };
};

export default useExitPrompt;
