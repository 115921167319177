import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store/store";
import { setUser } from "../store/reducers/usersReducer";
import { setUserLogin } from "../store/reducers/authReducer";

function BasicLayout() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // without getUserProfileApi
        let user= localStorage.getItem("user")
        if(user) {
        user = JSON.parse(user);
        }
        const token = localStorage.getItem("token");
        //@ts-ignore
        if(user) dispatch(setUser(user));
        if(token) dispatch(setUserLogin({token: token}));
        else navigate("/login");
    })

    return <Outlet />
}
export default BasicLayout;