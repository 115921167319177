import { Avatar, Button, Fade, Grid, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CallEndIcon from "@mui/icons-material/CallEnd";
import { useEffect, useState } from "react";
import { Call } from "@mui/icons-material";
import DeviceWorker from "../../../lib/DeviceWorker";

const CallConnect = (props: {
  endCall: () => void;
  bargeIn: () => void;
  data: Record<string, any>;
  worker: DeviceWorker;
  isOpen: boolean;
}) => {
  const { endCall, data, isOpen, bargeIn, worker } = props;

  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    if (hours > 0) {
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    } else {
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
  };

  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={isOpen}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <div
          style={{
            backgroundColor: "#1D1F30",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            padding: "20px",
            borderRadius: "8px",
            width: "700px",
            height: "500px",
            outline: 0,
          }}
        >
          <Box sx={{ height: "inherit", position: "relative" }}>
            <Box
              display="flex"
              sx={{
                backgroundColor: "#1D1F30",
                padding: "20px",
                display: "flex",
                width: "95%",
                height: "95%",
                position: "relative",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width={"30%"}
              >
                <Avatar
                  sx={{
                    width: "8rem",
                    height: "8rem",
                    backgroundColor: "#373854",
                    color: "#696C79",
                    marginBottom: "0.8rem",
                  }}
                />
                <Typography variant="body2" color="white" fontWeight="bold">
                  {formatTime(timer)}
                </Typography>
                <Typography variant="body2" color="white" fontWeight="bold">
                  {data?.phoneNo}
                </Typography>
              </Box>

              <Box width={"100%"} height={"85%"} sx={{ overflowY: "scroll" }}>
                <Grid container spacing={2} sx={{ marginBottom: "0.5rem" }}>
                  {data &&
                    Object.keys(data?.data).map((key, index) => {
                      const value = data?.data[key];
                      if (
                        value === undefined ||
                        value === null ||
                        value === ""
                      ) {
                        return null;
                      }
                      let displayValue = value;
                      if (typeof value === "boolean") {
                        displayValue = value ? "Yes" : "No";
                      }
                      return (
                        <Grid
                          key={index}
                          item
                          xs={5}
                          marginBottom={"0.5rem"}
                          marginLeft={"5%"}
                        >
                          <Typography
                            variant="body2"
                            color="grey"
                            fontSize={"12px"}
                          >
                            {key}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="white"
                            fontWeight="bold"
                            sx={{ overflowWrap: "break-word" }}
                          >
                            {displayValue}
                          </Typography>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Box>
            {worker.showBarge && (
              <Button
                variant="contained"
                color="success"
                sx={{
                  position: "absolute",
                  bottom: "3%",
                  right: "25%",
                }}
                onClick={bargeIn}
              >
                <Call sx={{ marginRight: "1rem" }} />
                Barge In
              </Button>
            )}
            <Button
              variant="contained"
              color="error"
              sx={{
                position: "absolute",
                bottom: "3%",
                right: "3%",
              }}
              onClick={endCall}
            >
              <CallEndIcon sx={{ marginRight: "1rem" }} />
              END CALL
            </Button>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};

export default CallConnect;
