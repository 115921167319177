import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: '#007bff',
    },
    secondary: {
      main: "rgb(251,170,41)",
    },
    success: {
      main: "#28a745",
    },
    error: {
      main: "#dc3545",
    },
    warning: {
      main: "#ffc107",
    },
    info: {
      main: "#17a2b8",
    },
    // You can define other palette colors here
  },
  typography: {
    fontSize: 14,
  },
  shape: {
    borderRadius: 6,
  },
  spacing: (factor: number) => `${0.25 * factor}rem`,
  // You can add more properties like typography, spacing, etc. as needed
});

export default theme;
