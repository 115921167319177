import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  token: string;
}

const initialState: AuthState = {
  token: ""
};

export const loginSlice = createSlice({
  name: "userLogin",
  initialState,
  reducers: {
    setUserLogin: (state, action: PayloadAction<AuthState>) => {
      state.token = action.payload.token
      return state;
    },
    resetUserLogin: (state) => {
      state = initialState
      return state;
    }
  },
});

export const { setUserLogin, resetUserLogin } = loginSlice.actions;

export default loginSlice.reducer;
