import {
  Backdrop,
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import styles from "./index.module.css";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  closeHandler: () => void;
  confirmHandler: () => void;
  message: string;
  title: string;
  confirmText: string;
};

const ConfirmationModal = ({
  closeHandler,
  title,
  message,
  confirmText,
  confirmHandler,
}: Props) => {
  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={true}
      onClose={() => closeHandler()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div className={styles.modal}>
          {false && (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                zIndex: 9998,
              }}
            >
              <Box
                sx={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  zIndex: 9999,
                }}
              >
                <CircularProgress />
              </Box>
            </Box>
          )}
          <div className={styles.modalBody}>
            <IconButton
              aria-label="close"
              onClick={() => closeHandler()}
              style={{
                position: "absolute",
                top: "20px",
                right: "5px",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{ margin: 0 }}
                id="transition-modal-title"
                style={{ position: "relative" }}
              >
                {title}
              </Typography>
            </Box>
          </div>
          <div className={styles.modalContainer}>
            <p>{message}</p>
            <div>
              <Button onClick={closeHandler} variant="contained" color="error">
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={confirmHandler}
                color="secondary"
              >
                {confirmText}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ConfirmationModal;
