import { Backdrop, Button, Fade, Modal, Typography } from "@mui/material";
import React from "react";

type Props = {
  open: boolean;
  onClose: () => void;
};

const MediaAccess = (props: Props) => {
  const { open, onClose } = props;

  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div
          style={{
            backgroundColor: "white",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            padding: "20px",
            borderRadius: "8px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            maxWidth: 500,
            outline: 0,
          }}
        >
          <Typography variant="h5">Device permissions</Typography>
          <br />
          <Typography variant="body2">
            <span style={{ color: "red" }}>Unable to initiate the call!</span>,
            Please verify that the browser has permission to access the
            microphone and you have working hardware to capture the audio and
            sound
          </Typography>
          <br />
          <Button
            variant="contained"
            sx={{ marginRight: 0, marginLeft: "auto" }}
            onClick={onClose}
          >
            OK
          </Button>
        </div>
      </Fade>
    </Modal>
  );
};

export default MediaAccess;
