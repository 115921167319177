type CB = () => void;

let callbacks: Map<number, CB> = new Map();
let runningIds: number[] = [];
let started = true;
let forceStop = false;
const nexRunCallbacks: Map<number, CB> = new Map();

function useQueue(frequency = 1, reactAtEnd = () => {}) {
  const start = () => {
    console.log({ runningIds, callbacks, nexRunCallbacks, started, forceStop });

    const items = [...nexRunCallbacks.entries(), ...callbacks.entries()];
    if (started && !forceStop && items.length === 0) {
      reactAtEnd();
    }
    if (!started || items.length === 0 || forceStop) return false;
    for (let index = 0; index < items.length; index++) {
      const [id, cb] = items[index];
      if (runningIds.length === frequency) break;
      if (!runningIds.includes(id)) {
        cb();
        runningIds.push(id);
      }
    }
  };

  const take = (id: number, cb: CB) => {
    callbacks.set(id, cb);
    setTimeout(start, 0);
  };

  const leave = (id: number, removeFromCallback = true) => {
    console.log({ leave: id });
    runningIds = runningIds.filter((rId) => rId !== id);
    callbacks.delete(id);
    if (removeFromCallback) {
      nexRunCallbacks.delete(id);
    }
    setTimeout(start, 0);
  };

  const next = (id: number, cb: CB) => {
    nexRunCallbacks.set(id, cb);
    setTimeout(start, 0);
  };

  const stop = (force = false) => {
    started = false;
    if (force) {
      forceStop = true;
    }
    runningIds = [];
  };

  const init = (forceStart = false) => {
    started = true;
    if (forceStart) {
      forceStop = false;
    }
    setTimeout(start, 0);
  };

  return {
    take,
    leave,
    next,
    stop,
    init,
  };
}

export default useQueue;
