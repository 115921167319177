import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BasicLayout from "./layouts/BasicLayout";
import Register from "./pages/Register";
import UpdateDetails from "./pages/UpdateDetails";
import { lazy } from "react";
import Scripts from "./pages/Scripts";
import { ThemeProvider } from "@mui/system";
import theme from "./theme";

const Login = lazy(() => import("./pages/Login"));
const Home = lazy(() => import("./pages/home"));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route element={<BasicLayout />}>
            <Route index element={<Home />} />
            <Route index path="/home" element={<Home />} />
            <Route path="prospects/:id" element={<UpdateDetails />} />
            <Route path="/scripts" element={<Scripts />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Register />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
