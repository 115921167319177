import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Header from "../../components/Header";
import styles from "./index.module.css";
import {
  useDeleteScriptMutation,
  useGetAllScriptsQuery,
  useGetTemplatesQuery,
} from "../../services/api";
import { useState } from "react";
import ScripModal from "../../components/ModalComponents/ScriptModal";
import { CreateOutlined, Delete } from "@mui/icons-material";
import ConfirmationModal from "../../components/ModalComponents/Confirmation";
import { toast } from "react-toastify";

function Scripts() {
  const { data, isFetching, refetch } = useGetAllScriptsQuery();
  const { data: templates } = useGetTemplatesQuery();
  const [deleteScript] = useDeleteScriptMutation();
  const [query, setQuery] = useState<string>("");
  const matches = useMediaQuery("(max-width:600px)");
  const buttonSize = matches ? "small" : "medium";

  const [addModalOpened, setAddModalOpened] = useState<boolean>(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);
  const [selectedScript, setSelectedScript] = useState<Script | undefined>(
    undefined
  );

  const deleteHandler = async () => {
    if (!selectedScript) return;
    const { error } = (await deleteScript(selectedScript.id)) as {
      data: null;
      error: any;
    };
    if (error) {
      return toast(`Unknown error occured while deleting script`, {
        type: "error",
      });
    }
    toast(`${selectedScript.name} deleted successfully`);
    setSelectedScript(undefined);
    setDeleteModalOpened(false);
    refetch();
  };

  return (
    <div>
      {((selectedScript && !deleteModalOpened) || addModalOpened) && (
        <ScripModal
          templates={templates?.data || []}
          script={selectedScript}
          edit={!!selectedScript}
          closeHandler={(refresh) => {
            setSelectedScript(undefined);
            setAddModalOpened(false);
            if (refresh) refetch();
          }}
        />
      )}
      {deleteModalOpened && (
        <ConfirmationModal
          closeHandler={() => {
            setDeleteModalOpened(false);
            setSelectedScript(undefined);
          }}
          confirmHandler={deleteHandler}
          message={`Are you sure you want to delete "${selectedScript?.name}"`}
          title="Delete Script"
          confirmText="Delete"
        />
      )}
      <Header setVoiceModal={(val) => val} />
      <div className={styles.containerHeader}>
        <div>
          <h1>Scripts</h1>
          <h3>{data?.data?.length || 0}</h3>
        </div>
        <div>
          <TextField
            className={styles.search}
            label="Search"
            size="small"
            variant="outlined"
            onChange={(e) => setQuery(e.target.value)}
          />
          <Button
            style={{ marginLeft: "10px" }}
            color="secondary"
            variant="contained"
            size={buttonSize}
            onClick={() => setAddModalOpened(true)}
          >
            Create New
          </Button>
        </div>
      </div>
      {isFetching ? (
        <CircularProgress className={styles.loader} />
      ) : (
        <div className={styles.containerBody}>
          <table className={styles.table}>
            <tr>
              <th>NAME</th>
              <th>CREATED AT</th>
              <th>LAST MODIFIED</th>
              <th>LIST</th>
              <th>ACTIONS</th>
            </tr>
            {data?.data
              .filter((script) =>
                query.length > 0 ? script.name.includes(query) : true
              )
              .map((script: Script, index: number) => (
                <tr>
                  <td>{script.name}</td>
                  <td>
                    {script.createdAt.split("T")[0]}
                  </td>
                  <td>
                    {script.updatedAt.split("T")[0]}
                  </td>
                  <td>{script.template.name}</td>
                  <td>
                    <IconButton
                      onClick={() => setSelectedScript(script)}
                      color="primary"
                    >
                      <CreateOutlined />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSelectedScript(script);
                        setDeleteModalOpened(true);
                      }}
                      color="error"
                    >
                      <Delete />
                    </IconButton>
                  </td>
                </tr>
              ))}
          </table>
        </div>
      )}
    </div>
  );
}

export default Scripts;
