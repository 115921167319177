import config from "../configs/env";
import { mulaw } from "alawmulaw";

export class AudioPlayer {
  audioContext: AudioContext;
  socket: WebSocket;
  token: string;
  identity: string;
  type: "retell" | "twilio";
  constructor({
    token,
    identiy,
    type,
  }: {
    token: string;
    identiy: string;
    type: "retell" | "twilio";
  }) {
    this.audioContext = new AudioContext({ sampleRate: 8000 });
    this.token = token;
    this.identity = identiy;
    this.type = type;
    this.socket = new WebSocket(
      `wss://${config.baseUrl?.replace(/https?:\/\//, "")}/listen/${
        this.identity
      }?token=${this.token}&type=${this.type}`
    );
  }

  async bargeIn() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    await this.audioContext.audioWorklet.addModule("worklet/mic-processor.js");
    const micNode = new AudioWorkletNode(this.audioContext, "mic-processor", {
      channelCount: 1,
      channelCountMode: "explicit",
      numberOfInputs: 1,
    });

    const source = this.audioContext.createMediaStreamSource(stream);
    source.connect(micNode);

    micNode.port.onmessage = (event) => {
      const audioData = mulaw.encode(event.data);
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(audioData);
      }
      // if (audioData.some((val: number) => val > 0)) console.log(audioData);
    };
  }

  async refresh() {
    this.audioContext.close();
    
    if([2,3].includes(this.socket.readyState)) {
      this.socket = new WebSocket(
        `wss://${config.baseUrl?.replace(/https?:\/\//, "")}/listen/${
          this.identity
        }?token=${this.token}&type=${this.type}`
      );
    }

    this.audioContext = new AudioContext({ sampleRate: 8000 });
    await this.audioContext.audioWorklet.addModule(
      "worklet/audio-processor.js"
    );
    const workletNode = new AudioWorkletNode(
      this.audioContext,
      "audio-processor"
    );
    workletNode.connect(this.audioContext.destination);
    this.socket.binaryType = "arraybuffer";
    this.socket.onmessage = (event) => {
      const data = event.data;
      workletNode.port.postMessage(data);
    };

    this.socket.onopen = () => {
      console.log("WebSocket connection opened");
    };

    this.socket.onclose = () => {
      console.log("WebSocket connection closed");
    };
  }
}
