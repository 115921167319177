import React, { PropsWithChildren } from 'react';



type State = {
    hasError: boolean;
};

class ErrorBoundary extends React.Component<PropsWithChildren, State> {
    constructor(props: any) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError() {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error: any, errorInfo: any) {
      // You can also log the error to an error reporting service
      console.log(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        return <h1>Something went wrong.</h1>;
      }
  
      return this.props.children; 
    }
  }

  const ErrorBoundaryWithSuspense = (props: PropsWithChildren) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <ErrorBoundary>{props.children}</ErrorBoundary>
  </React.Suspense>
);
  
  export default ErrorBoundaryWithSuspense;