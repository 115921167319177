/* eslint-disable react-hooks/exhaustive-deps */
import {
  PropsWithChildren,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  useBargeInMutation,
  useCallProspectsMutation,
  useEndCallMutation,
  useGetDeviceTokenQuery,
  useGetUserFrequencyQuery,
} from "../services/api";
import CallConnect from "../components/ModalComponents/CallConnect";
import MediaAccess from "../components/ModalComponents/MediaAccess";
import useExitPrompt from "../hooks/useExitPrompt";
import { useAppSelector } from "../store/store";
import DeviceWorker from "../lib/DeviceWorker";
import { useSocket } from "./SocketProvider";
import useQueue from "../hooks/useQueue";
import config from "../configs/env";
import PCMPlayer from "pcm-player";
import { MuLawDecoder } from "../lib/MuLawDecoder";
import { AudioPlayer } from "../lib/AudioPlayer";

export enum CallMode {
  DIRECT = "Direct call",
  AI = "AI",
  SCRIPT = "Script",
}

interface DeviceProp {
  callProspects: (data: Map<number, Table>) => void;
  callMode: CallMode;
  setCallMode: (arg0: CallMode) => void;
  currentScriptId?: number;
  setCurrentScriptId: (arg0?: number) => void;
  callStatus: "IDLE" | "ENGAGED" | "INITIATING";
  refresh: () => void;
  isPaused: boolean;
  updatedProspects: Map<number, Table>;
  togglePause: () => void;
}

interface CallData {
  isConnected: boolean;
  data: Table | null;
  worker: DeviceWorker | null;
  phoneNo: string | null;
}

export enum CallStatus {
  IDLE = "Idle",
  RINGING = "Ringing",
  CONNECTED = "Connected",
  NOANSWER = "Noanswer",
  ENDED = "Ended",
  FAILED = "Failed",
  CALLBACK = "Callback",
  CONNECTING = "Connecting",
  INITIATED = "INITIATED",
}

const initialValues: DeviceProp = {
  callProspects: (data: Map<number, Table>) => {},
  callStatus: "IDLE",
  callMode: CallMode.DIRECT,
  setCallMode() {},
  setCurrentScriptId() {},
  refresh() {},
  isPaused: false,
  updatedProspects: new Map(),
  togglePause: () => {},
};

const initailCallData: CallData = {
  isConnected: false,
  data: null,
  worker: null,
  phoneNo: null,
};

enum SocketEvents {
  RINGING = "RINGING",
  ANSWERED = "ANSWERED",
  COMPLETED = "COMPLETED",
  NO_ANSWER = "NOANSWER",
  FAILED = "FAILED",
}

export const DeviceContext = createContext<DeviceProp>(initialValues);

function DeviceProvider(props: PropsWithChildren) {
  const token: string = useAppSelector((state) => state.auth.token);
  const { data } = useGetDeviceTokenQuery(undefined, { skip: !token });
  const { data: frequency = 1 } = useGetUserFrequencyQuery();
  const [workers, setWorkers] = useState<DeviceWorker[]>([]);
  const [cHead, setCHead] = useState(0);
  const [callData, setCallData] = useState(initailCallData);
  const [showMediaPermModal, setShowMediaPermModal] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const [currentScriptId, setCurrentScriptId] = useState<number>();
  const [callMode, setCallMode] = useState<CallMode>(CallMode.DIRECT);
  const [automatedCall] = useCallProspectsMutation();
  const [endCall] = useEndCallMutation();
  const [bargeIn] = useBargeInMutation();

  const [callStatus, setCallStatus] =
    useState<DeviceProp["callStatus"]>("IDLE");
  const [prospects, setProspects] = useState<DeviceProp["updatedProspects"]>(
    new Map()
  );

  const pcmRetellPlayerRef = useRef<any>();
  const pcmTwilioPlayerRef = useRef<AudioPlayer>();

  const { socket } = useSocket();
  const queue = useQueue(frequency, () => {
    setCallStatus("IDLE");
  });

  // capture all call events;
  useEffect(() => {
    if (!socket) return;

    // call ringing
    socket?.on(SocketEvents.RINGING, (...args): void => {
      const [prosId, , phoneNo] = args;
      setCallStatus("ENGAGED");
      updateStatus(prosId, CallStatus.RINGING, phoneNo);
    });

    // call connected
    socket?.on(SocketEvents.ANSWERED, (...args): void => {
      const [prosId, , phoneNo] = args;
      updateStatus(prosId, CallStatus.CONNECTED, phoneNo);
    });

    // call error or rejected
    socket?.on(SocketEvents.NO_ANSWER, async (...args) => {
      const [prosId, , phoneNo] = args;
      updateStatus(prosId, CallStatus.NOANSWER, phoneNo);
    });

    socket?.on(SocketEvents.FAILED, async (...args) => {
      const [prosId, , phoneNo] = args;
      updateStatus(prosId, CallStatus.FAILED, phoneNo);
    });

    // call ended
    socket?.on(SocketEvents.COMPLETED, (...args): void => {
      const [prosId, , phoneNo] = args;
      updateStatus(prosId, CallStatus.ENDED, phoneNo);
    });

    // clean up
    return () => {
      socket?.off(SocketEvents.RINGING);
      socket?.off(SocketEvents.ANSWERED);
      socket?.off(SocketEvents.COMPLETED);
      socket?.off(SocketEvents.NO_ANSWER);
      socket?.off(SocketEvents.FAILED);
    };
  }, [socket, prospects.size, workers.length, callData]);

  useEffect(() => {
    const token = data?.token;

    if (!token || !pcmRetellPlayerRef.current) return;

    const decoder = new MuLawDecoder();

    const retellSocket = new WebSocket(
      `wss://${config.baseUrl?.replace(/https?:\/\//, "")}/listen/${
        data.identity
      }?token=${token}&type=retell`
    );

    retellSocket.onopen = () => {
      console.log("Retell connection open");
    };

    retellSocket.binaryType = "arraybuffer";

    retellSocket.onmessage = async (message) => {
      const decoded = decoder.muLawDecodeBuffer(message.data);
      pcmRetellPlayerRef.current.feed(decoded);
    };

    return () => {
      retellSocket.close();
    };
  }, [pcmRetellPlayerRef.current, data]);

  // register device and call events
  const removeWorker = (worker: DeviceWorker) => {
    worker.disconnect();
    setWorkers((workers) => workers.filter((item) => item.id !== worker.id));
  };

  useEffect(() => {
    for (let i = 0; i < workers.length; i++) {
      const worker = workers[i];

      worker?.device.on("error", async (twilioError) => {
        removeWorker(worker);
      });

      worker?.callRef?.on("error", async (error) => {
        console.log({ error });
        if ([31401, 31402].includes(error.code)) {
          setShowMediaPermModal(true);
          setCallStatus("IDLE");
        } else {
          removeWorker(worker);
        }
      });
    }

    return () => {
      for (let i = 0; i < workers.length; i++) {
        const worker = workers[i];

        worker?.device?.removeAllListeners();
        worker?.callRef?.removeAllListeners();
      }
    };
  }, [cHead, workers]);

  const call = (id: number, prospect: Table) => {
    const device = new DeviceWorker(data.token, callMode, {
      prospect: prospect,
      id: `${id}`,
      identity: data.identity,
      scriptId: currentScriptId as number,
      onMediaError: () => mediaErr(id),
      automatedCall,
      endCall,
    });
    setWorkers((workers) => [...workers, device]);
  };

  /**
   * initialize a new call
   * Can be called on first process init
   */
  const initCall = async (prospects: Map<number, Table>) => {
    setProspects(prospects);
    await new Promise((res) => setTimeout(res, 1000));
    setCallStatus("INITIATING");
    setCHead(0);

    if (!pcmRetellPlayerRef.current) {
      pcmRetellPlayerRef.current = new PCMPlayer({
        inputCodec: "Int16",
        channels: 1,
        sampleRate: 8000,
        flushTime: 1000,
        fftSize: 2048,
      });
      pcmRetellPlayerRef.current.volume(2);
    }

    if (!pcmTwilioPlayerRef.current) {
      pcmTwilioPlayerRef.current = new AudioPlayer({
        token: data.token,
        identiy: data.identity,
        type: "twilio",
      });
    }

    pcmTwilioPlayerRef.current.refresh();
    if (callMode === CallMode.SCRIPT) pcmTwilioPlayerRef.current.bargeIn();

    for (const item of prospects) {
      const [id, prospect] = item;
      queue.take(id, () => {
        call(id, prospect);
      });
    }
  };

  // set back to idle
  const refresh = () => {
    setCHead(0);
    setCallStatus("IDLE");
  };

  // disable direct refresh after initializing a call
  const { setShowExitPrompt } = useExitPrompt({ onRefresh: refresh });
  useEffect(() => {
    if (callStatus !== "IDLE") setShowExitPrompt(true);
    else setShowExitPrompt(false);
  }, [callStatus]);

  // show media Error
  const mediaErr = (id: number) => {
    setShowMediaPermModal(true);
    setCallStatus("IDLE");
    setWorkers([]);
    queue.leave(id);
  };

  const handleUpdateForProspect = (id: number, status: CallStatus) => {
    if (!id || !status) return;
    console.log({ id, status });
    const prospect = prospects.get(id)!;
    if (prospect) {
      const connectAfterEnded =
        prospect.status === CallStatus.ENDED && status === CallStatus.CONNECTED;
      const queueEnded =
        prospect.status === CallStatus.CALLBACK &&
        status === CallStatus.NOANSWER;
      if (!connectAfterEnded && !queueEnded) {
        prospects.set(id, { ...prospect, status });
        setProspects(new Map(prospects));
      }
    }
  };

  const handleCallEnded = (id: number, phoneNo: string) => {
    socketCleanup();
    const disconnected = callData.isConnected && phoneNo === callData.phoneNo;
    if (disconnected) {
      queue.init();
      console.log("Ended", callData);
    }

    setCallData((callData) => {
      if (disconnected) {
        return {
          isConnected: false,
          data: null,
          worker: null,
          phoneNo: null,
        };
      }
      return callData;
    });
    const worker = workers.find((work) => work.prospect?.id == id);
    if (worker) {
      removeWorker(worker);
    }
  };
  const handleCallConnected = async (id: number, phoneNo: string) => {
    const data = prospects.get(id);
    const worker = workers.find((work, i) => work.prospect?.id == id);
    const isConnected = data && worker ? true : false;
    console.log("Connected", { isConnected, id, data, worker });

    if (callData.isConnected && callData.worker) {
      pushCallBack(callData.worker);
      return;
    }
    setCallData({
      isConnected: isConnected,
      data: data || null,
      phoneNo: phoneNo,
      worker: worker || null,
    });

    if (worker && isConnected) {
      pushCallBack(worker);
    }
  };

  const socketCleanup = () => {
    try {
      pcmRetellPlayerRef.current?.destroy();
      pcmRetellPlayerRef.current = null;
      pcmTwilioPlayerRef.current?.refresh();
    } catch (err) {
      console.log(err);
    }
  };

  const handleCallFailedOrNoAnswer = (id: number, phoneNo: string) => {
    socketCleanup();
    const prospect = prospects.get(id)!;
    if (prospect) {
      queue.leave(id, prospect.status !== CallStatus.CALLBACK);
    }
    const index = workers.findIndex((work, i) => work.prospect?.id == id);
    if (index !== -1) {
      const temp = workers[index];
      removeWorker(temp);
    }
    return true;
  };
  // update prospects status && process workers
  const updateStatus = (_id: string, status: CallStatus, phoneNo: string) => {
    const id = parseInt(_id);
    handleUpdateForProspect(id, status);
    switch (status) {
      case CallStatus.ENDED:
        handleCallEnded(id, phoneNo);
        queue.leave(id);
        break;

      case CallStatus.CONNECTED: {
        handleCallConnected(id, phoneNo);
        break;
      }
      case CallStatus.FAILED:
        handleCallFailedOrNoAnswer(id, phoneNo);
        queue.leave(id);
        break;
      case CallStatus.NOANSWER:
        handleCallFailedOrNoAnswer(id, phoneNo);
        break;
      default: {
      }
    }
  };

  const pushCallBack = (worker: DeviceWorker) => {
    for (let i = 0; i < workers.length; i++) {
      const item = workers[i];
      if (item.id !== worker.id) {
        item.disconnect();
        const prospect = item.prospect;
        if (prospect) {
          queue.leave(prospect.id);
          queue.next(prospect.id, () => call(prospect.id, prospect));
          handleUpdateForProspect(prospect.id, CallStatus.CALLBACK);
        }
      }
    }
    setWorkers([worker]);
    queue.stop();
  };

  // ============================================

  return (
    <DeviceContext.Provider
      value={{
        callProspects: initCall,
        callMode,
        setCallMode,
        currentScriptId,
        setCurrentScriptId,
        callStatus,
        updatedProspects: prospects,
        refresh,
        isPaused,
        togglePause: () => {
          setIsPaused((prvs) => !prvs);
          isPaused ? queue.init(true) : queue.stop(true);
        },
      }}
    >
      {props.children}

      <MediaAccess
        open={showMediaPermModal}
        onClose={() => {
          setShowMediaPermModal(false);
        }}
      />

      {callData.isConnected && (
        <CallConnect
          data={callData.data!}
          worker={callData.worker!}
          bargeIn={async () => {
            if (!callData.worker || !callData.worker.callSid) return;
            pcmRetellPlayerRef.current?.destroy();
            pcmRetellPlayerRef.current = null;
            pcmTwilioPlayerRef.current?.bargeIn();
            callData.worker.showBarge = false;
          }}
          endCall={() => {
            console.log("End call manually");
            callData.worker?.disconnect();
            queue.leave(callData.data?.id!);
            queue.init();
            setCallData({
              isConnected: false,
              data: null,
              worker: null,
              phoneNo: null,
            });
          }}
          isOpen={callData.isConnected}
        />
      )}
    </DeviceContext.Provider>
  );
}

export default DeviceProvider;
