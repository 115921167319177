

import * as yup from 'yup';

  yup.addMethod(yup.string, 'startsWithPlus', function (errorMessage) {
    return this.test('startsWithPlus', errorMessage, function (value) {
      if (!value || typeof value !== 'string') {
        return false;
      }
      return value.startsWith('+');
    });
  });

  const loginValidation = yup.object().shape({
      email: yup
      .string()
      .email("Please enter email")
      .required("Email is required"),
      password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{8,}$/,
        "Please enter valid password"
      )
      .min(8, "Password must be at least 8 characters"),
  });

  const prospactusSchema = yup.object().shape({
    name: yup.string().max(100, 'Name is too large!').optional(),
    phoneNo: yup
    .string()
    .matches(/^[0-9+]+$/, 'Invalid! Please enter valid number')
    .required('Phone number is required')
    .max(14, "Phone number shuld not be max 14")
    .test('startsWithPlus', 'Phone number must start with "+"', function (value) {
      if (!value || typeof value !== 'string') {
        return false;
      }
      return value.startsWith('+');
    }),
    company: yup.string().max(100, 'Company is too large!').optional(),
    title: yup.string().max(100, 'Title is too large!').optional(),
    email: yup.string().optional().email('Invalid email format'),
    linkedin: yup.string().optional().url('Invalid URL format'),
    note: yup.string().optional(),
  });
  
  export { loginValidation, prospactusSchema };